import { Injectable } from "@angular/core";
import { IAuth, AuthModel } from "./models/i-auth";
import { SendMail } from "./models/send-mail";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { IResultRequest } from "./models/i-result-request";

@Injectable({
  providedIn: "root"
})
export class SharedService {
  constructor(private httpClient: HttpClient) {}
  baseUrl = environment.apiUrlBase;
  setTokenSession(token: IAuth) {
    window.sessionStorage.setItem("id", token.id);
    window.sessionStorage.setItem("token", token.token);
  }

  getTokenSession(): IAuth {
    const model = new AuthModel();
    model.id = window.sessionStorage.getItem("id");
    model.token = window.sessionStorage.getItem("token");
    return model;
  }

  getUserId(): string {
    return window.sessionStorage.getItem("id");
  }

  removeTokenSession() {
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("id");
  }

  sendEmail(mensage: SendMail) {
    console.log(mensage);
    let obj: any = mensage;
    obj.typeCoating = window.localStorage.getItem('typeCoating');
    return this.httpClient.post<IResultRequest>(
      `${this.baseUrl}/users/sendResultsToUser`,
      obj
    );
  }
 
  ///Obter permissão das telas (por enquanto)

  private screenPermission: string[];
  setPermission(screen: string){
    this.screenPermission = [screen];
    localStorage.setItem('permission', JSON.stringify(this.screenPermission))
  }

  getPermission(requiredPermission: string): boolean{
    const screenPermission = JSON.parse(localStorage.getItem('permission'));
    return screenPermission && screenPermission.includes(requiredPermission);
  }

  ///Obter avaliação
  private selectedStars: number = 0;

  getSelectedStars(): number {
    return this.selectedStars;
  }

  setSelectedStars(stars: number): void {
    this.selectedStars = stars;
  }


}
