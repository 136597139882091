import { IResultRequest } from "./../shared/models/i-result-request";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { EnvironmentsFilter } from "./models/filters/environments-filter";
import { IEnviroment } from "./models/i-enviroment";
import { NovoRevestimentoFilter } from "./models/filters/novo-revestimento-filter";
import { Globals } from '../globals';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class RevestimentoService {
  constructor(private httpClient: HttpClient, private globals: Globals) { }

  private standsFilter: string[] = [];
  private stilesFilter: string[] = [];
  private colorFilter: string[] = [];
  private colorWallFilter: string[] = [];
  private standsWallFilter: string[] = [];

  private obrasFilter: string[] = [];
  private desempenhoFilter: string[] = [];
  private isFilterUpdate = false;
  private canShowFilter = false;
  private canShowFilterEnviroment = false;
  private canShowFilterEnviromentBox = false;
  private canShowFilterRevestimento = false;
  private backMore = new BehaviorSubject(false);
  backM = this.backMore.asObservable();

  baseUrl = environment.apiUrlBase;
  private idContrapisoregular = environment.idContraPisoRegular;
  private idEmboco = environment.idEmboco;

  changeValue(bool: boolean) {
    this.backMore.next(bool);
  }

  showFilterBox() {
    return this.canShowFilterEnviromentBox;
  }

  setShowFilterEnviromentBox(state: boolean) {
    this.canShowFilterEnviromentBox = state;
  }

  getIdContraPisoRegular() {
    return this.idContrapisoregular;
  }

  getIdEmboco() {
    return this.idEmboco;
  }

  hasFilterUpdate(): boolean {
    return this.isFilterUpdate;
  }

  setFilterUpdate(state: boolean) {
    this.isFilterUpdate = state;
  }

  setShowFilter(state: boolean) {
    this.canShowFilter = state;
  }

  showFilter() {
    return this.canShowFilter;
  }

  setShowFilterEnviroment(state: boolean) {
    this.canShowFilterEnviroment = state;
  }

  showFilterEnviroment() {
    return this.canShowFilterEnviroment;
  }

  setShowFilterRevestimento(state: boolean) {
    this.canShowFilterRevestimento = state;
  }

  showFilterRevestimento() {
    return this.canShowFilterRevestimento;
  }

  getEnvironmentsById(id: string) {
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/public/environments/${id}`
    );
  }

  getEnvironments(environmentsFilter?: EnvironmentsFilter) {
    if (
      environmentsFilter &&
      (environmentsFilter.colorId ||
        environmentsFilter.standardId ||
        environmentsFilter.styleId ||
        environmentsFilter.colorWallId ||
        environmentsFilter.standardWallId)
    ) {
      const parameters = new HttpHeaders()
        .set("colorId", environmentsFilter.colorId)
        .set("standardId", environmentsFilter.standardId)
        .set("styleId", environmentsFilter.styleId)
        .set("colorWallId", environmentsFilter.colorWallId)
        .set("standardWallId", environmentsFilter.standardWallId);
        // .set("whitelabelId", "6c32cc02-c5bc-4170-af1f-02eefbead082");

      return this.httpClient.get<IResultRequest>(
        `${this.baseUrl}/filter/environments`, {headers: parameters}
      );
    } else {
      return this.httpClient.get<IResultRequest>(
        `${this.baseUrl}/filter/environments`
      );
    }
  }

  getEnvironmentsSemelhantes(id: string) {
    const parameters = new HttpHeaders()
      .set("Content-Type", "application/json; charset=utf-8")
      .set("id", id);
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/filterByEnvironment`,
      { headers: parameters }
    );
  }

  getPreexistence(typeCoating?: string) {
    const parameters = new HttpHeaders().set("coating", typeCoating);
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/public/preexistences`, {headers: parameters}
    );
  }

  getPossibleActions(preexistenceid, materialid) {
    let parameters = new HttpHeaders()
      .set("preexistenceid", preexistenceid)
      .set("materialid", materialid);

    let area = this.getParamnsSelected('AreaM2');

    let envExclusive = this.getParamnsSelected('envExclusive');
    let envId = this.getParamnsSelected('envId');
    if(envExclusive) parameters = parameters.append("envExclusive", envExclusive);
    if(envId) parameters = parameters.append("envId", envId);
    let standardtemp = this.getParamnsSelected('standardEnvId');
    let colortemp = this.getParamnsSelected('colorEnvId');
    let standard;
    let color;
    if (standardtemp == 'null') standard = null;
    else standard = standardtemp;
    if (colortemp == 'null') color = null;
    else color = colortemp;

    parameters = parameters.append("area", area);
    if (color != null) {
      parameters = parameters.append("color", color);
    }
    if (standard != null) {
      parameters = parameters.append("standard", standard);
    }


    let obra = this.getParamnsSelected('obraPerformance');
    let material = this.getParamnsSelected('materialPerformance');
    if (material.includes('agua')) {
      parameters = parameters.append('waterResistant', "Sim");
    }
    if (material.includes('antiderrapante')) {
      parameters = parameters.append('nonslip', "Sim")
    }
    if (material.includes('risco')) {
      parameters = parameters.append('scratchResistant', "Sim")
    }
    if (material.includes('abrasao')) {
      parameters = parameters.append('stainResistant', "Sim")
    }
    if (material.includes('frio')) {
      parameters = parameters.append('cold', "Sim")
    }
    if (material.includes('Uso comercial')) {
      parameters = parameters.append('commercialUse', "Sim")
    }
    if (material.includes('externo')) {
      parameters = parameters.append('externalLocation', "Sim")
    }
    if (obra.includes("Sem barulho")) {
      parameters = parameters.append('noise', 'Não');
    }
    if (obra.includes("Sem cheiro")) {
      parameters = parameters.append('smell', 'Não');
    }
    if (obra.includes("Sem poeira")) {
      parameters = parameters.append('dust', 'Não');
    }
    if (obra.includes("Sem resíduo")) {
      parameters = parameters.append('residue', 'Não');
    }
    let typeCoating = this.getParamnsSelected('typeCoating');
    if(typeCoating) parameters = parameters.append('typeCoating', typeCoating);
    
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/listByMaterialAndPreexistence`,
      { headers: parameters }
    );
  }

  getActionsPrice(id: string) {
    let parameters = new HttpHeaders()
      .set("possibleactionid", id);

    let area = this.getParamnsSelected('AreaM2');
    let token = this.getParamnsSelected('token');

    let standardtemp = this.getParamnsSelected('standardEnvId');
    let colortemp = this.getParamnsSelected('colorEnvId');
    let standard;
    let color;
    let envExclusive = this.getParamnsSelected('envExclusive');
    let envId = this.getParamnsSelected('envId');
    if(envExclusive) parameters = parameters.append("envExclusive", envExclusive);
    if(envId) parameters = parameters.append("envId", envId);
    if (standardtemp == 'null') standard = null;
    else standard = standardtemp;
    if (colortemp == 'null') color = null;
    else color = colortemp;

    parameters = parameters.append("area", area);
    parameters = parameters.append("tokenUser", token);
    if (color != null) {
      parameters = parameters.append("color", color);
    }
    if (standard != null) {
      parameters = parameters.append("standard", standard);
    }


    let obra = this.getParamnsSelected('obraPerformance');
    let material = this.getParamnsSelected('materialPerformance');
    if (material.includes('agua')) {
      parameters = parameters.append('waterResistant', "Sim");
    }
    if (material.includes('antiderrapante')) {
      parameters = parameters.append('nonslip', "Sim")
    }
    if (material.includes('risco')) {
      parameters = parameters.append('scratchResistant', "Sim")
    }
    if (material.includes('abrasao')) {
      parameters = parameters.append('stainResistant', "Sim")
    }
    if (material.includes('frio')) {
      parameters = parameters.append('cold', "Sim")
    }
    if (material.includes('Uso comercial')) {
      parameters = parameters.append('commercialUse', "Sim")
    }
    if (material.includes('externo')) {
      parameters = parameters.append('externalLocation', "Sim")
    }
    if (obra.includes("Sem barulho")) {
      parameters = parameters.append('noise', 'Não');
    }
    if (obra.includes("Sem cheiro")) {
      parameters = parameters.append('smell', 'Não');
    }
    if (obra.includes("Sem poeira")) {
      parameters = parameters.append('dust', 'Não');
    }
    if (obra.includes("Sem resíduo")) {
      parameters = parameters.append('residue', 'Não');
    }

    // parameters = parameters.append("retail_id", 'ec5dfd70-9446-47db-90f3-460c270961de');
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/getPossibleActionsPrice`,
      { headers: parameters }
    );
  }

  getActionsPriceToSaveAnswers(id: string) {
    let parameters = new HttpHeaders()
      .set("possibleactionid", id);

    let area = this.getParamnsSelected('AreaM2');

    let standardtemp = this.getParamnsSelected('standardEnvId');
    let colortemp = this.getParamnsSelected('colorEnvId');
    let envExclusive = this.getParamnsSelected('envExclusive');
    let envId = this.getParamnsSelected('envId');
    if(envExclusive) parameters = parameters.append("envExclusive", envExclusive);
    if(envId) parameters = parameters.append("envId", envId);
    let standard;
    let color;
    if (standardtemp == 'null') standard = null;
    else standard = standardtemp;
    if (colortemp == 'null') color = null;
    else color = colortemp;

    parameters = parameters.append("area", area);
    if (color != null) {
      parameters = parameters.append("color", color);
    }
    if (standard != null) {
      parameters = parameters.append("standard", standard);
    }


    let obra = this.getParamnsSelected('obraPerformance');
    let material = this.getParamnsSelected('materialPerformance');
    if (material.includes('agua')) {
      parameters = parameters.append('waterResistant', "Sim");
    }
    if (material.includes('antiderrapante')) {
      parameters = parameters.append('nonslip', "Sim")
    }
    if (material.includes('risco')) {
      parameters = parameters.append('scratchResistant', "Sim")
    }
    if (material.includes('abrasao')) {
      parameters = parameters.append('stainResistant', "Sim")
    }
    if (material.includes('frio')) {
      parameters = parameters.append('cold', "Sim")
    }
    if (material.includes('Uso comercial')) {
      parameters = parameters.append('commercialUse', "Sim")
    }
    if (material.includes('externo')) {
      parameters = parameters.append('externalLocation', "Sim")
    }
    if (obra.includes("Sem barulho")) {
      parameters = parameters.append('noise', 'Não');
    }
    if (obra.includes("Sem cheiro")) {
      parameters = parameters.append('smell', 'Não');
    }
    if (obra.includes("Sem poeira")) {
      parameters = parameters.append('dust', 'Não');
    }
    if (obra.includes("Sem resíduo")) {
      parameters = parameters.append('residue', 'Não');
    }
    // parameters = parameters.append("retail_id", 'ec5dfd70-9446-47db-90f3-460c270961de');
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/getPossibleActionsPrice`,
      { headers: parameters }
    );

  }

  getPossibleActionsPrice(preexistenceid, materialid) {
    let parameters = new HttpHeaders()
      .set("preexistenceid", preexistenceid)
      .set("materialid", materialid);

    let area = this.getParamnsSelected('AreaM2');
    let token = this.getParamnsSelected('token');
    // parameters = parameters.append("retail_id", 'ec5dfd70-9446-47db-90f3-460c270961de');

    let standardtemp = this.getParamnsSelected('standardEnvId');
    let colortemp = this.getParamnsSelected('colorEnvId');
    let envExclusive = this.getParamnsSelected('envExclusive');
    let envId = this.getParamnsSelected('envId');
    if(envExclusive) parameters = parameters.append("envExclusive", envExclusive);
    if(envId) parameters = parameters.append("envId", envId);
    let standard;
    let color;
    if (standardtemp == 'null') standard = null;
    else standard = standardtemp;
    if (colortemp == 'null') color = null;
    else color = colortemp;

    parameters = parameters.append("area", area);
    parameters = parameters.append("tokenUser", token);
    if (color != null) {
      parameters = parameters.append("color", color);
    }
    if (standard != null) {
      parameters = parameters.append("standard", standard);
    }


    let obra = this.getParamnsSelected('obraPerformance');
    let material = this.getParamnsSelected('materialPerformance');
    if (material.includes('agua')) {
      parameters = parameters.append('waterResistant', "Sim");
    }
    if (material.includes('antiderrapante')) {
      parameters = parameters.append('nonslip', "Sim")
    }
    if (material.includes('risco')) {
      parameters = parameters.append('scratchResistant', "Sim")
    }
    if (material.includes('abrasao')) {
      parameters = parameters.append('stainResistant', "Sim")
    }
    if (material.includes('frio')) {
      parameters = parameters.append('cold', "Sim")
    }
    if (material.includes('Uso comercial')) {
      parameters = parameters.append('commercialUse', "Sim")
    }
    if (material.includes('externo')) {
      parameters = parameters.append('externalLocation', "Sim")
    }
    if (obra.includes("Sem barulho")) {
      parameters = parameters.append('noise', 'Não');
    }
    if (obra.includes("Sem cheiro")) {
      parameters = parameters.append('smell', 'Não');
    }
    if (obra.includes("Sem poeira")) {
      parameters = parameters.append('dust', 'Não');
    }
    if (obra.includes("Sem resíduo")) {
      parameters = parameters.append('residue', 'Não');
    }
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/getActionsPrice`,
      { headers: parameters }
    );
  }


  getColors() {
    return this.httpClient.get<IResultRequest>(`${this.baseUrl}/public/colors`);
  }

  getStandards() {
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/public/standards`
    );
  }

  getStiles() {
    return this.httpClient.get<IResultRequest>(`${this.baseUrl}/public/styles`);
  }

  listMaterialsWithPrice(filter: NovoRevestimentoFilter) {
    let parameters = new HttpHeaders();
    let preexist = this.getParamnsSelected('PreexistenceID');
    let area = this.getParamnsSelected('AreaM2');

    let standardtemp = this.getParamnsSelected('standardEnvId');
    let colortemp = this.getParamnsSelected('colorEnvId');
    let standard;
    let color;
    if (standardtemp == 'null') standard = null;
    else standard = standardtemp;
    if (colortemp == 'null') color = null;
    else color = colortemp;


    parameters = parameters.append("area", area);
    parameters = parameters.append("preexistenceId",
      preexist
    );
    if (color != null) {
      parameters = parameters.append("color", color);
    }
    if (standard != null) {
      parameters = parameters.append("standard", standard);
    }
    if (filter.usageFilter || filter.performanceFilter) {
      if (filter.performanceFilter.waterResistant) {
        parameters = parameters.append(
          "waterResistant",
          filter.performanceFilter.waterResistant
        );
      }
      if (filter.performanceFilter.stainResistant) {
        parameters = parameters.append(
          "stainResistant",
          filter.performanceFilter.stainResistant
        );
      }
      if (filter.performanceFilter.scratchResistant) {
        parameters = parameters.append(
          "scratchResistant",
          filter.performanceFilter.scratchResistant
        );
      }
      if (filter.performanceFilter.nonSlip) {
        parameters = parameters.append(
          "nonSlip",
          filter.performanceFilter.nonSlip
        );
      }
      if (filter.performanceFilter.commercialUse) {
        parameters = parameters.append(
          "commercialUse",
          filter.performanceFilter.commercialUse
        );
      }
      if (filter.performanceFilter.externalLocation) {
        parameters = parameters.append(
          "externalLocation",
          filter.performanceFilter.externalLocation
        );
      }
      if (filter.performanceFilter.cold) {
        parameters = parameters.append("cold", filter.performanceFilter.cold);
      }
      if (filter.usageFilter.usageType) {
        parameters = parameters.append(
          "usageType",
          filter.usageFilter.usageType
        );
      }
      if (filter.usageFilter.environmentType) {
        parameters = parameters.append(
          "environmentType",
          filter.usageFilter.environmentType
        );
      }
      if (filter.obraFilter.dust) {
        parameters = parameters.append(
          "dust",
          filter.obraFilter.dust
        );
      }
      if (filter.obraFilter.noise) {
        parameters = parameters.append(
          "noise",
          filter.obraFilter.noise
        );
      }
      if (filter.obraFilter.residue) {
        parameters = parameters.append(
          "residue",
          filter.obraFilter.residue
        );
      }
      if (filter.obraFilter.smell) {
        parameters = parameters.append(
          "smell",
          filter.obraFilter.smell
        );
      }
      if (filter.obraFilter.speed) {
        parameters = parameters.append(
          "speed",
          filter.obraFilter.speed
        );
      }
    }
    // parameters = parameters.append("retail_id", 'ec5dfd70-9446-47db-90f3-460c270961de');
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/listMaterialsWithPrice`,
      { headers: parameters }
    );
  }

  getMaterialPrice(filter: NovoRevestimentoFilter, id: string) {
    let parameters = new HttpHeaders();
    let preexist = this.getParamnsSelected('PreexistenceID');
    let area = this.getParamnsSelected('AreaM2');
    let token = this.getParamnsSelected('token');
    let standardtemp = this.getParamnsSelected('standardEnvId');
    let colortemp = this.getParamnsSelected('colorEnvId');
    let envExclusive = this.getParamnsSelected('envExclusive');
    let envId = this.getParamnsSelected('envId');
    if(envExclusive) parameters = parameters.append("envExclusive", envExclusive);
    if(envId) parameters = parameters.append("envId", envId);
    let standard;
    let color;
    if (standardtemp == 'null') standard = null;
    else standard = standardtemp;
    if (colortemp == 'null') color = null;
    else color = colortemp;

    // parameters = parameters.append("retail_id", 'ec5dfd70-9446-47db-90f3-460c270961de');
    parameters = parameters.append("area", area);
    parameters = parameters.append("tokenUser", token);
    parameters = parameters.append("preexistenceId",
      preexist
    );
    if (color != null) {
      parameters = parameters.append("color", color);
    }
    if (standard != null) {
      parameters = parameters.append("standard", standard);
    }

    parameters = parameters.append("materialid", id);

    let typeCoating = this.getParamnsSelected('typeCoating');
    if(typeCoating) parameters = parameters.append('typeCoating', typeCoating);

    if (filter.usageFilter || filter.performanceFilter) {

      if (filter.performanceFilter.waterResistant) {
        parameters = parameters.append(
          "waterResistant",
          filter.performanceFilter.waterResistant
        );
      }

      if (filter.performanceFilter.stainResistant) {
        parameters = parameters.append(
          "stainResistant",
          filter.performanceFilter.stainResistant
        );
      }

      if (filter.performanceFilter.scratchResistant) {
        parameters = parameters.append(
          "scratchResistant",
          filter.performanceFilter.scratchResistant
        );
      }

      if (filter.performanceFilter.nonSlip) {
        parameters = parameters.append(
          "nonSlip",
          filter.performanceFilter.nonSlip
        );
      }

      if (filter.performanceFilter.commercialUse) {
        parameters = parameters.append(
          "commercialUse",
          filter.performanceFilter.commercialUse
        );
      }

      if (filter.performanceFilter.externalLocation) {
        parameters = parameters.append(
          "externalLocation",
          filter.performanceFilter.externalLocation
        );
      }

      if (filter.performanceFilter.cold) {
        parameters = parameters.append("cold", filter.performanceFilter.cold);
      }

      if (filter.usageFilter.usageType) {
        parameters = parameters.append(
          "usageType",
          filter.usageFilter.usageType
        );
      }

      if (filter.usageFilter.environmentType) {
        parameters = parameters.append(
          "environmentType",
          filter.usageFilter.environmentType
        );
      }

      if (filter.obraFilter.dust) {
        parameters = parameters.append(
          "dust",
          filter.obraFilter.dust
        );
      }
      if (filter.obraFilter.noise) {
        parameters = parameters.append(
          "noise",
          filter.obraFilter.noise
        );
      }
      if (filter.obraFilter.residue) {
        parameters = parameters.append(
          "residue",
          filter.obraFilter.residue
        );
      }
      if (filter.obraFilter.smell) {
        parameters = parameters.append(
          "smell",
          filter.obraFilter.smell
        );
      }
      if (filter.obraFilter.speed) {
        parameters = parameters.append(
          "speed",
          filter.obraFilter.speed
        );
      }


    }

    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/getMaterialPrice`,
      { headers: parameters }
    );
  }

  listMaterialsByAppearanceAndPerformance(filter: NovoRevestimentoFilter) {
    let parameters = new HttpHeaders();
    let preexist = this.getParamnsSelected('PreexistenceID');
    let area = this.getParamnsSelected('AreaM2');

    let standardtemp = this.getParamnsSelected('standardEnvId');
    let colortemp = this.getParamnsSelected('colorEnvId');
    let envExclusive = this.getParamnsSelected('envExclusive');
    let envId = this.getParamnsSelected('envId');
    let typeCoating = this.getParamnsSelected('typeCoating');
    if(envExclusive) parameters = parameters.append("envExclusive", envExclusive);
    if(envId) parameters = parameters.append("envId", envId);
    if(typeCoating) parameters = parameters.append('typeCoating', typeCoating);
    let standard;
    let color;
    if (standardtemp == 'null') standard = null;
    else standard = standardtemp;
    if (colortemp == 'null') color = null;
    else color = colortemp;


    parameters = parameters.append("area", area);
    parameters = parameters.append("preexistenceId",
      preexist
    );
    if (color != null) {
      parameters = parameters.append("color", color);
    }
    if (standard != null) {
      parameters = parameters.append("standard", standard);
    }
    if (filter.usageFilter || filter.performanceFilter) {

      if (filter.performanceFilter.waterResistant) {
        parameters = parameters.append(
          "waterResistant",
          filter.performanceFilter.waterResistant
        );
      }

      if (filter.performanceFilter.stainResistant) {
        parameters = parameters.append(
          "stainResistant",
          filter.performanceFilter.stainResistant
        );
      }

      if (filter.performanceFilter.scratchResistant) {
        parameters = parameters.append(
          "scratchResistant",
          filter.performanceFilter.scratchResistant
        );
      }

      if (filter.performanceFilter.nonSlip) {
        parameters = parameters.append(
          "nonSlip",
          filter.performanceFilter.nonSlip
        );
      }

      if (filter.performanceFilter.commercialUse) {
        parameters = parameters.append(
          "commercialUse",
          filter.performanceFilter.commercialUse
        );
      }

      if (filter.performanceFilter.externalLocation) {
        parameters = parameters.append(
          "externalLocation",
          filter.performanceFilter.externalLocation
        );
      }

      if (filter.performanceFilter.cold) {
        parameters = parameters.append("cold", filter.performanceFilter.cold);
      }

      if (filter.usageFilter.usageType) {
        parameters = parameters.append(
          "usageType",
          filter.usageFilter.usageType
        );
      }

      if (filter.usageFilter.environmentType) {
        parameters = parameters.append(
          "environmentType",
          filter.usageFilter.environmentType
        );
      }

      if (filter.obraFilter.dust) {
        parameters = parameters.append(
          "dust",
          filter.obraFilter.dust
        );
      }
      if (filter.obraFilter.noise) {
        parameters = parameters.append(
          "noise",
          filter.obraFilter.noise
        );
      }
      if (filter.obraFilter.residue) {
        parameters = parameters.append(
          "residue",
          filter.obraFilter.residue
        );
      }
      if (filter.obraFilter.smell) {
        parameters = parameters.append(
          "smell",
          filter.obraFilter.smell
        );
      }
      if (filter.obraFilter.speed) {
        parameters = parameters.append(
          "speed",
          filter.obraFilter.speed
        );
      }
    }
    // parameters = parameters.append("whitelabelId", "6c32cc02-c5bc-4170-af1f-02eefbead082");
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/listMaterialsByAppearanceAndPerformance/${filter.id}`,
      { headers: parameters }
    );
  }

  listMaterialsByAppearanceAndPerformanceById(id) {
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/listMaterialsByAppearanceAndPerformance/${id}`
    );
  }

  trasListaDeMaterial() {
    return this.httpClient.get(
      `${this.baseUrl}/listMaterialsByAppearanceAndPerformance`
    );
  }

  setColorFilter(colors: string[]) {
    this.colorFilter = colors;
    this.setFilterUpdate(true);
  }

  setStandardFilter(stands: string[]) {
    this.standsFilter = stands;
    this.setFilterUpdate(true);
  }

  setColorWallFilter(colors: string[]) {
    this.colorWallFilter = colors;
    this.setFilterUpdate(true);
  }

  setStandardWallFilter(stands: string[]) {
    this.standsWallFilter = stands;
    this.setFilterUpdate(true);
  }

  setStileFilter(stiles: string[]) {
    this.stilesFilter = stiles;
    this.setFilterUpdate(true);
  }

  setObrasFilter(obras: string[]) {
    this.obrasFilter = obras;
    this.setFilterUpdate(true);
  }

  setDesempenhoFilter(desempenho: string[]) {
    this.desempenhoFilter = desempenho;
    this.setFilterUpdate(true);
  }

  getColorFilter(): string[] {
    return this.colorFilter;
  }

  getColorWallFilter(): string[] {
    return this.colorWallFilter;
  }

  getStandardFilter(): string[] {
    return this.standsFilter;
  }

  getStandardWallFilter(): string[] {
    return this.standsWallFilter;
  }

  getStileFilter(): string[] {
    return this.stilesFilter;
  }

  getObrasFilter(): string[] {
    return this.obrasFilter;
  }

  getDesempenhoFilter(): string[] {
    return this.desempenhoFilter;
  }

  setParamnsSelected(key: string, value: any) {
    window.localStorage.setItem(key, value);
  }

  removeParamsSelected(key: string) {
    window.localStorage.removeItem(key);
  }

  getParamnsSelected(key: string) {
    const url = window.localStorage.getItem(key);
    return url;
  }

  setEnviromentImg(item: IEnviroment) {
    window.localStorage.setItem("imgurl", item.imgUrl);
  }

  getEnviromentImg() {
    const url = window.localStorage.getItem("imgurl");
    return url;
  }

  getParamnsSelectedList() {
    return {
      enviromentId: this.getParamnsSelected("EnviromentId"),
      appearanceId: this.getParamnsSelected("AppearanceId"),
      appearanceColorId: this.getParamnsSelected("colorEnvId"),
      appearanceStandardId: this.getParamnsSelected("standardEnvId"),
      styleId: this.getParamnsSelected("StyleId"),
      preexistenceID: this.getParamnsSelected("PreexistenceID"),
      materialID: this.getParamnsSelected("MaterialID"),
      possibleActionID: this.getParamnsSelected("PossibleActionID"), //ação
      areaM2: this.getParamnsSelected("AreaM2")
    };
  }

  getResume(actionid, preexistenceid, materialid) {
    const parameters = new HttpHeaders()
      .set("actionid", actionid)
      .set("preexistenceid", preexistenceid)
      .set("materialid", materialid);
    return this.httpClient.get<IResultRequest>(
      `${this.baseUrl}/listInterventionsByActionsAndPreexistenceAndMaterial`,
      { headers: parameters }
    );
  }

  getQuestions(ids) {
    let id = ids.split(',');
    return this.httpClient.post<IResultRequest>(`${this.baseUrl}/listQuestionsByInterventionsId`, { ids: id });
  }

  updateLeadByEmail(emailUser, listName, resumeProducts) {
    console.log('resumeProd', resumeProducts);
    return this.httpClient.patch<IResultRequest>(`${this.baseUrl}/leads/public/` + emailUser, { listName: listName, resumeProducts: resumeProducts });
  }

  getTotalProductsPrice(selecteds, state) {
    let parameters = new HttpHeaders();
    let area = this.getParamnsSelected('AreaM2');
    let token = this.getParamnsSelected('token');
    let standardtemp = this.getParamnsSelected('standardEnvId');
    let colortemp = this.getParamnsSelected('colorEnvId');
    let interv = localStorage.getItem('ids');
    let interventionIds = interv.split(',');
    let standard;
    let color;
    if (standardtemp == 'null') standard = null;
    else standard = standardtemp;
    if (colortemp == 'null') color = null;
    else color = colortemp;

    let questionAnswers = JSON.parse(this.getParamnsSelected('questionAnswer'));
    let answersQuestions = questionAnswers;
    let answersQuestionsIds = [];
    if (answersQuestions) {
      answersQuestions.forEach(element => {
        answersQuestionsIds.push([element.answer, element.question])
      });
    }

    let envExclusive = this.getParamnsSelected('envExclusive');
    let envId = this.getParamnsSelected('envId');
    if(envExclusive) parameters = parameters.append("envExclusive", envExclusive);
    if(envId) parameters = parameters.append("envId", envId);

    parameters = parameters.append("questionsanswers", answersQuestionsIds);
    parameters = parameters.append("area", area);
    parameters = parameters.append("tokenUser", token);
    if (color != null) {
      parameters = parameters.append("color", color);
    }
    if (standard != null) {
      parameters = parameters.append("standard", standard);
    }
    parameters = parameters.append("interventionids", interventionIds);
    let prodSelecteds = []
    for (let i = 0; i < selecteds.length; i++) {
      if (selecteds[i].packagedProduct && Object.keys(selecteds[i].packagedProduct).length > 0)
        prodSelecteds.push([selecteds[i].groupId,
        selecteds[i].interventionId,
        selecteds[i].productCategoryId,
        selecteds[i].packagedProduct.productId,
        selecteds[i].packagedProduct.id,
        selecteds[i].invalid]);
    }
    parameters = parameters.append("productsSelecteds", prodSelecteds);

    let matPerf = localStorage.getItem('materialPerformance');
    let externalLocation, commercialUse;
    if (matPerf.includes("comercial")) {
      commercialUse = "Sim";
    }

    if (matPerf.includes("externo")) {
      externalLocation = "Sim";
    }

    parameters.append("commercialUse", commercialUse);
    parameters.append("externalLocation", externalLocation);

    let material = this.getParamnsSelected('materialPerformance');
    if (material.includes('agua')) {
      parameters = parameters.append('waterResistant', "Sim");
    }
    if (material.includes('antiderrapante')) {
      parameters = parameters.append('nonslip', "Sim")
    }
    if (material.includes('risco')) {
      parameters = parameters.append('scratchResistant', "Sim")
    }
    if (material.includes('abrasao')) {
      parameters = parameters.append('stainResistant', "Sim")
    }
    if (material.includes('frio')) {
      parameters = parameters.append('cold', "Sim")
    }
    parameters = parameters.append("state", state);
    // parameters = parameters.append("retail_id", 'ec5dfd70-9446-47db-90f3-460c270961de');
    return this.httpClient.get<IResultRequest>(`${this.baseUrl}/getTotalProductsPrice`, { headers: parameters })
  }

  getDetailPrice(questionAnswers) {
    let parameters = new HttpHeaders();
    let area = this.getParamnsSelected('AreaM2');
    let standardtemp = this.getParamnsSelected('standardEnvId');
    let colortemp = this.getParamnsSelected('colorEnvId');
    let envExclusive = this.getParamnsSelected('envExclusive');
    let envId = this.getParamnsSelected('envId');
    if(envExclusive) parameters = parameters.append("envExclusive", envExclusive);
    if(envId) parameters = parameters.append("envId", envId);
    let interv = localStorage.getItem('ids');
    let interventionIds = interv.split(',');
    let standard;
    let color;
    if (standardtemp == 'null') standard = null;
    else standard = standardtemp;
    if (colortemp == 'null') color = null;
    else color = colortemp;


    let material = this.getParamnsSelected('materialPerformance');
    if (material) {
      if (material.includes('agua')) {
        parameters = parameters.append('waterResistant', "Sim");
      }
      if (material.includes('antiderrapante')) {
        parameters = parameters.append('nonslip', "Sim")
      }
      if (material.includes('risco')) {
        parameters = parameters.append('scratchResistant', "Sim")
      }
      if (material.includes('abrasao')) {
        parameters = parameters.append('stainResistant', "Sim")
      }
      if (material.includes('frio')) {
        parameters = parameters.append('cold', "Sim")
      }
    }

    let answersQuestions = questionAnswers;
    let answersQuestionsIds = [];
    if (answersQuestions) {
      answersQuestions.forEach(element => {
        answersQuestionsIds.push([element.answer, element.question])
      });
    }

    parameters = parameters.append("questionsanswers", answersQuestionsIds);
    parameters = parameters.append("area", area);
    if (color != null) {
      parameters = parameters.append("color", color);
    }
    if (standard != null) {
      parameters = parameters.append("standard", standard);
    }
    parameters = parameters.append("interventionids", interventionIds);
    let matPerf = localStorage.getItem('materialPerformance');
    let externalLocation, commercialUse;
    if (matPerf) {
      if (matPerf.includes("comercial")) {
        commercialUse = "Sim";
      }

      if (matPerf.includes("externo")) {
        externalLocation = "Sim";
      }
    }

    parameters.append("commercialUse", commercialUse);
    parameters.append("externalLocation", externalLocation);
    // parameters.append("retail_id", 'ec5dfd70-9446-47db-90f3-460c270961de');
    return this.httpClient.get<IResultRequest>(`${this.baseUrl}/getTotalProductsPrice`, { headers: parameters })
    // return this.httpClient.get<IResultRequest>(`${this.baseUrl}/getDetailPrice`, { headers: parameters })
  }

  getPackagedProduct(id, state) {
    // console.log('tst1')
    // retail_id: 'ec5dfd70-9446-47db-90f3-460c270961de' 
    return this.httpClient.get<IResultRequest>(`
    ${this.baseUrl}/packagedProducts/getPackagedProductById/${id}`, { headers: { state: state} });
  }

  getProductsByInterventionAndAnswer(id: string, prodId, prodCatId, filter, state) {
    let interventionId = [id];
    let answersQuestions = JSON.parse(localStorage.getItem('questionAnswer'));
    let answersQuestionsIds = [];
    let colorEnvId = localStorage.getItem('colorEnvId');
    let standardEnvId = localStorage.getItem('standardEnvId');
    if (standardEnvId == "null") standardEnvId = null;
    if (colorEnvId == "null") colorEnvId = null;
    if (answersQuestions) {
      answersQuestions.forEach(element => {
        answersQuestionsIds.push([element.answer, element.question])
      });
    }
    let temp = true;
    for (let i = 0; i < this.globals.prodCat.length; i++) {
      if (prodCatId == this.globals.prodCat[i][1]) {
        this.globals.prodCat[i][0] = prodId;
        temp = false;
      }
    }
    if (temp) this.globals.prodCat.push([prodId, prodCatId]);

    let preexistenceid = [localStorage.getItem('PreexistenceID')];
    let area = [localStorage.getItem('AreaM2')];
    this.globals.prodCat = this.globals.prodCat.filter(function (el) {
      return el[0] != null
    });

    let matPerf = localStorage.getItem('materialPerformance');
    let externalLocation, commercialUse;
    if (matPerf) {
      if (matPerf.includes("comercial")) {
        commercialUse = "Sim";
      }
      if (matPerf.includes("externo")) {
        externalLocation = "Sim";
      }
    }
    // console.log("filterfilterfilterfilter");
    let envExclusive = this.getParamnsSelected('envExclusive');
    let envId = this.getParamnsSelected('envId');

    
    let dust = filter.obraFilter.dust;
    let smell = filter.obraFilter.smell;
    let noise = filter.obraFilter.noise;
    let residue = filter.obraFilter.residue;
    let speed = filter.obraFilter.speed;
    let cold = filter.performanceFilter.cold;
    let nonSlip = filter.performanceFilter.nonSlip;
    let scratchResistant = filter.performanceFilter.scratchResistant;
    let stainResistant = filter.performanceFilter.stainResistant;
    let waterResistant = filter.performanceFilter.waterResistant;

    if(!filter.obraFilter && !filter.performanceFilter){
      dust = "";
      smell = "";
      noise = "";
      residue = "";
      speed = "";
      cold = "";
      nonSlip = "";
      scratchResistant = "";
      stainResistant = "";
      waterResistant = "";
    }
    let request = {
      "interventionId": interventionId, "anwersQuestionsIds": answersQuestionsIds, "area": area, "preexistenceId": preexistenceid, "productsAndCategoryProdutcsIds": this.globals.prodCat, "colorEnvId": colorEnvId,
      "standardEnvId": standardEnvId, "dust": dust, "smell": smell, "noise": noise,
      "residue": residue, "speed": speed, "coldSelected": cold, "nonSlipSelected": nonSlip,
      "scratchResistantSelected": scratchResistant, "stainResistantSelected": stainResistant, "waterResistantSelected": waterResistant,
      "externaLocationSelected": externalLocation, "commercialUseSelected": commercialUse, "state": state, "envexclusive": envExclusive, "envid": envId
    };
    // , { headers: { retail_id: 'ec5dfd70-9446-47db-90f3-460c270961de' }}
    return this.httpClient.post<IResultRequest>(`${this.baseUrl}/groups/getSuggestedProductsByInterventionAndAnswers`, request );
  }

  listInterventionsAndProductCategorysByInterventionsAndResponses() {
    let interv = localStorage.getItem('ids');
    let interventionIds = interv.split(',');
    let answersQuestions = JSON.parse(localStorage.getItem('questionAnswer'));
    let answersQuestionsIds = [];
    if (answersQuestions) {
      answersQuestions.forEach(element => {
        answersQuestionsIds.push([element.answer, element.question])
      });
    }

    let request = { "interventionIds": interventionIds, "anwersQuestionsIds": answersQuestionsIds }
    return this.httpClient.post<IResultRequest>(`${this.baseUrl}/listInterventionsAndProductCategorysByInterventionsAndResponses`, request);

  }

  sendRating(email: string, comment: string, evaluation: number){
    return this.httpClient.patch<IResultRequest>(`${this.baseUrl}/leads/public/${email}`, 
    {
      comment: comment, 
      evaluation: evaluation
    });
  }

  getLead(email: string) {
    return this.httpClient.get<IResultRequest>(`${this.baseUrl}/leads/public/${email}`);
  }

  getStores(state: string) {
    return this.httpClient.get<IResultRequest>(`${this.baseUrl}/getByState/${state}`);
  }
}
