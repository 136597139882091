import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RevestimentoService } from 'src/app/revestimento/revestimento.service';
import { Globals } from 'src/app/globals';
import { Router } from "@angular/router";
import { SharedService } from '../shared.service';

@Component({
  selector: 'pro-my-list',
  templateUrl: './my-list.component.html',
  styles: []
})
export class MyListComponent implements OnInit {

  @Input() selecteds;
  @Output() proOnSelected: EventEmitter<any> = new EventEmitter();
  public selected = [];
  public categories = [];
  public interventions = [];
  public interventionsAndProducts;
  public totalPriceProducts = 0;
  public totalPriceProductsServices = 0;
  public totalPriceServices = 0;
  public save = false;
  // public afterSave = false;
  public actualRoute;
  listName: string;
  constructor(private revestimentoService: RevestimentoService, private globals: Globals, private route: Router, private authService: SharedService) { }

  ngOnInit() {
    // console.log("oi minha lista");

    this.actualRoute = this.route.url;
    // console.log("routeActive: ", this.route.url)
    // console.log("selceted", this.selecteds);
    // this.selected = this.selected.filter(el => {return el != null});

    this.revestimentoService.listInterventionsAndProductCategorysByInterventionsAndResponses().subscribe(suc => {
      console.log("suc", suc['result']);
      this.interventionsAndProducts = suc['result'];
      suc['result'].forEach(element => {
        this.interventions.push({ "intervention": element })
      });

      for (let sb = 0; sb < this.selecteds.length; sb++) {
        if (Object.keys(this.selecteds[sb].packagedProduct).length > 0) {
          this.selecteds[sb].id = this.selecteds[sb].packagedProduct.products.id;
        }
      }
      this.selected = [];
      // console.log("interventions", this.interventions);
      // console.log("intev", this.interventionsAndProducts);
      for (let i = 0; i < this.interventionsAndProducts.length; i++) {
        for (let j = 0; j < this.interventionsAndProducts[i].length; j++) {
          for (let k = 0; k < this.interventionsAndProducts[i][j].productCategories.length; k++) {
            for (let l = 0; l < this.interventionsAndProducts[i][j].productCategories[k].length; l++) {
              if (this.interventionsAndProducts[i][j].productCategories[k][l].isComplementary) {
                // let prodAp = this.interventionsAndProducts[i][j].productCategories[k][l].fatherId;
                // let hasFatherSelected = false;
                // Sel:
                // for (let ka = 0; ka < this.selecteds.length; ka++) {
                //   if (Object.keys(this.selecteds[ka].packagedProduct).length) {
                //     if (this.selecteds[ka].packagedProduct.products.id == prodAp) {
                //       hasFatherSelected = true;
                //       break Sel;
                //     }
                //   }
                // }
                let proSel = this.selecteds.find(ac => (ac.isComplementary == true) &&
                  (ac.productCategoryId == this.interventionsAndProducts[i][j].productCategories[k][l].id) &&
                  ac.interventionId == this.interventionsAndProducts[i][j].interventionId &&
                  ac.groupId == this.interventionsAndProducts[i][j].id);
                if (proSel) {

                  let checkFather = this.selecteds.find(ac => ac.id == proSel.father);
                  if (checkFather) {
                    this.interventionsAndProducts[i][j].productCategories[k][l].hasFatherSelected = true;
                  } else {
                    this.interventionsAndProducts[i][j].productCategories[k][l].hasFatherSelected = false;
                  }

                  // if (proSel.hasFatherSelected == true) {
                  //   this.interventionsAndProducts[i][j].productCategories[k][l].hasFatherSelected = true;
                  // } else {
                  //   this.interventionsAndProducts[i][j].productCategories[k][l].hasFatherSelected = false;
                  // }
                } else {
                  this.interventionsAndProducts[i][j].productCategories[k][l].hasFatherSelected = false;
                }
              }
              for (let m = 0; m < this.selecteds.length; m++) {
                // console.log("m", m);
                // console.log("this.selecteds[m]",this.selecteds[m]);
                if (this.selecteds[m]) {
                  if (this.selecteds[m].groupId == this.interventionsAndProducts[i][j].id && this.selecteds[m].interventionId == this.interventionsAndProducts[i][j].interventionId && this.selecteds[m].productCategoryId == this.interventionsAndProducts[i][j].productCategories[k][l].id && Object.keys(this.selecteds[m].packagedProduct).length > 0) {
                    this.interventionsAndProducts[i][j].productCategories[k][l].packagedProduct = this.selecteds[m].packagedProduct;
                    // console.log("invalidd???????????", this.selecteds[m].invalid)
                    this.interventionsAndProducts[i][j].productCategories[k][l].interventionIdx = this.selecteds[m].interventionIdx;
                    if (this.selecteds[m].invalid) {
                      this.interventionsAndProducts[i][j].productCategories[k][l].lowPrice = -1;
                    }
                  }
                }
              }
            }
          }
        }
      }

      for (let i = 0; i < this.interventions.length; i++) {
        for (let j = 0; j < this.interventions[i].intervention.length; j++) {
          // console.log("aeae", this.interventions[i].intervention[j]);
          if (this.interventions[i].intervention[j].productCategories.length > 0) {
            for (let k = 0; k < this.interventions[i].intervention[j].productCategories.length; k++) {
              for (let l = 0; l < this.interventions[i].intervention[j].productCategories[k].length; l++) {
                this.selected.push({ "intervention": this.interventions[i].intervention[j].interventionId, "productCategory": this.interventions[i].intervention[j].productCategories[k][l].id })
                if (this.interventions[i].intervention[j].productCategories[k][l].lowPrice != 999999999 && this.interventions[i].intervention[j].productCategories[k][l].lowPrice != -1) {
                  if (this.interventions[i].intervention[j].productCategories[k][l].packagedProduct && !this.interventions[i].intervention[j].productCategories[k][l].packagedProduct.storeSelected) {
                    this.interventions[i].intervention[j].productCategories[k][l].packagedProduct.products.isService ? (this.totalPriceServices += this.interventions[i].intervention[j].productCategories[k][l].packagedProduct.minVlrConsumo) : (this.totalPriceProducts += this.interventions[i].intervention[j].productCategories[k][l].packagedProduct.minVlrConsumo)
                    this.totalPriceProductsServices += this.interventions[i].intervention[j].productCategories[k][l].packagedProduct.minVlrConsumo;
                  } else if (this.interventions[i].intervention[j].productCategories[k][l].packagedProduct && this.interventions[i].intervention[j].productCategories[k][l].packagedProduct.storeSelected) {
                    if (!isNaN(this.interventions[i].intervention[j].productCategories[k][l].packagedProduct.storeSelected.vlrConsumo)) {
                      this.interventions[i].intervention[j].productCategories[k][l].packagedProduct.products.isService? (this.totalPriceServices += this.interventions[i].intervention[j].productCategories[k][l].packagedProduct.storeSelected.vlrConsumo) : (this.totalPriceProducts += this.interventions[i].intervention[j].productCategories[k][l].packagedProduct.storeSelected.vlrConsumo);
                      this.totalPriceProductsServices += this.interventions[i].intervention[j].productCategories[k][l].packagedProduct.storeSelected.vlrConsumo;
                    }
                  }
                }
              }

            }
          }
        }
      }

      //salva a lista automaticamente ao entrar na tela.
      this.saveListAutomatic();
    })
  }

  saveListAutomatic(){
    let resumeProducts = localStorage.getItem('resumeProducts');
    let emailUser = localStorage.getItem('emailUser');
    let questionAnswer = localStorage.getItem('questionAnswer');

    let resumeProductsJson = JSON.parse(resumeProducts);
    let questionAnswerJson = JSON.parse(questionAnswer);

    resumeProductsJson.push(questionAnswerJson);

    let resumeProductsNew = JSON.stringify(resumeProductsJson);

    this.revestimentoService.updateLeadByEmail(emailUser, "lista_salva_automatica", resumeProductsNew).subscribe(suc => {
      console.log('sucesso', suc);
      localStorage.setItem('emailLead', suc.result.email)
    },
      error => {
        console.log("error", error);
      })
  }

  onOpenMenu() {
    let resumeProducts = localStorage.getItem('resumeProducts');
    let emailUser = localStorage.getItem('emailUser');
    let questionAnswer = localStorage.getItem('questionAnswer');

    let resumeProductsJson = JSON.parse(resumeProducts);
    let questionAnswerJson = JSON.parse(questionAnswer);

    resumeProductsJson.push(questionAnswerJson);

    let resumeProductsNew = JSON.stringify(resumeProductsJson);


    this.revestimentoService.updateLeadByEmail(emailUser, this.listName, resumeProductsNew).subscribe(suc => {
      let listComplete = localStorage.getItem('listComplete');

      if (listComplete != "false") {
        // console.log("Lead Salvo!");
        this.globals.listProd = false;
        this.globals.product = false;
        this.globals.resumoReforma = false;
        this.globals.especification = false;
        this.globals.prodCat = [];

        // localStorage.clear();
        this.authService.setPermission('evaluation');
        this.route.navigate(["avaliacao/"]);
      }
      else {
        localStorage.setItem('listComplete', "");
        this.globals.listProd = false;
      }

    },
      error => {
        console.log("error", error);
      })
  }

  onBackScreen() {
    this.globals.listProd = !this.globals.listProd;
  }

  onNavigate() {
    this.save = !this.save;
  }

  openStoreUrl(linkStore) {
    if (linkStore.match("https://")) {
      window.open(linkStore, '_blank');
    }
    else if (linkStore.match("http://")) {
      window.open(linkStore, '_blank');
    }
    else {
      window.open("https://" + linkStore, '_blank');
    }
  }

  onDisplaySave() {
    this.save = !this.save;
  }

  onDisplayAfterSave() {
    // this.save = !this.save;
    // this.afterSave = !this.afterSave;
    this.onOpenMenu();
  }

  onSelected(interv, category) {
    // console.log("intervae", interv);
    // console.log("categ", category);
    this.selected.forEach(element => {
      // console.log("teste", element);
      if (element.intervention == interv.id) {
        element.category = category.id;
      }
    });
    // console.log("selecteda", this.selected);

  }

  showStores(cat) {
    // console.log("categ?", cat);
    cat.backMyList = true;
    this.proOnSelected.emit(cat);
  }

}
