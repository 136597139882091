import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";

@Component({
  selector: "pro-expansion-panel",
  templateUrl: "./expansion-panel.component.html",
  styleUrls: ["./expansion-panel.component.css"],
})
export class ExpansionPanelComponent {
  @ViewChild(MatExpansionPanel, { static: false })
  expansionPanel: MatExpansionPanel;

  isPanelOpen: boolean = false;
  @Input() customExpandedHeight: string | null = null;
  @Input() customCollapsedHeight: string | null = null;
  @Input() selectedOption: string;
  @Input() radioOptions: any[] = [];
  @Input() tempSelectedOption: string;
  @Output() optionSelected = new EventEmitter<any>();
  @Output() onPanelChange = new EventEmitter<any>();

  onPanelOpenedChange(opened: boolean): void {
    this.isPanelOpen = opened;

    if (!opened && this.tempSelectedOption) {
      this.selectedOption = this.tempSelectedOption;
    }
  }

  checkPanelChange() {
    this.onPanelChange.emit();
  }

  onSelectOption(option: any): void {
    this.optionSelected.emit(option);

    // Fechando após seleção
    this.expansionPanel.close();
    this.checkPanelChange();
  }

  getOptionLabel(optionValue: string): string {
    const selectedOption = this.radioOptions.find(
      (option) => option.value === optionValue
    );
    return selectedOption ? selectedOption.label : "";
  }
}
