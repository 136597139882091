import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
    listProd: boolean = false;
    product: boolean = false;
    resumoReforma: boolean = false;
    especification: boolean = false;
    prodCat = [];
    sel = [];
    custometer: boolean = true;
    realIndex: number = -1;
}