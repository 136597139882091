import {
  Component,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { RevestimentoService } from "src/app/revestimento/revestimento.service";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import {
  MatBottomSheetRef,
  MatBottomSheet,
} from "@angular/material/bottom-sheet";
@Component({
  selector: "pro-proforma-navbar",
  templateUrl: "./proforma-navbar.component.html",
  styleUrls: ["./proforma-navbar.component.css"],
})
export class ProformaNavbarComponent implements AfterViewInit {
  constructor(
    private revestimentoService: RevestimentoService,
    private location: Location,
    private router: Router,
  ) {}
  @Input() showBack = null;
  @Input() showCart = false;

  @Output() onClickBack = new EventEmitter<void>();
  @Output() onClickCart = new EventEmitter<void>();

  leftMenuStatus: 'openModalMenu' | 'closeModalMenu' | '' = '';
  subscritions = new Subscription();
  menuModalStatus: 'openModalChange' | 'closeModalChange' | '' = '';

  preventClickPropagation(event: Event) {
    event.stopPropagation();
  }

  clickBackHandler() {
    this.onClickBack.emit();
    if(this.leftMenuStatus === "openModalMenu"){
      this.menuLeftClick();
    }
  }

  handleCart() {
    this.onClickCart.emit();
  }

  ngAfterViewInit() {}

  menuLeftClick() {
    if (this.leftMenuStatus === "openModalMenu") this.leftMenuStatus = "closeModalMenu"
    else this.leftMenuStatus = "openModalMenu";

    this.showBack = !this.showBack;
  }

  openCloseModalEmail(){
    if (this.menuModalStatus === "openModalChange") this.menuModalStatus = "closeModalChange";
    else this.menuModalStatus = "openModalChange";
    // this.menuLeftClick();
  }

  isEmail(): boolean {
    let serchfind = false;

    const regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    serchfind = regexp.test(this.email);
    return serchfind;
  }

  email;

  async goToNext() {
    localStorage.setItem("emailLead", this.email);
    this.openCloseModalEmail();    
    this.menuLeftClick();
    this.router.navigate(["leadResume"]);
  
    // this.revestimentoService.getLead(this.email).subscribe(
    //   (res) => {
    //     localStorage.setItem("emailLead", this.email);
    //     this.resultReforms = res['result']

    //     this.router.navigate(["leadResume", { lead: res["result"] }]);
    //   },
    //   (er) => {
    //     console.log("er", er);
    //     alert("Usuario não encontrado");
    //   }
    // );

  }
}
