export type customIcon = {
  iconName: string;
  iconPath: string;
};

export const iconsArray: customIcon[] = [
  {
    iconName: "menu",
    iconPath: "../../../assets/img/navbar/menu-hamburguer.svg",
  },
  {
    iconName: "back-button",
    iconPath: "../../../assets/img/navbar/back-button.svg",
  },
  {
    iconName: "cart-button",
    iconPath: "../../../assets/img/navbar/cart-button.svg",
  },
  {
    iconName: "funnel",
    iconPath: "../../../assets/img/footer/funnel.svg",
  },
  {
    iconName: "bolt",
    iconPath: "../../../assets/img/footer/bolt.svg",
  },
  {
    iconName: "info-circle",
    iconPath: "../../../assets/svg/revestimento/info-circle.svg",
  },
  {
    iconName: "cross-circle",
    iconPath: "../../../assets/svg/revestimento/cross-circle.svg",
  },
  {
    iconName: "question-circle",
    iconPath: '../../../assets/svg/revestimento/question-circle.svg'
  },
  {
    iconName: "pro-reforma-sobre",
    iconPath: '../../../assets/img/menu/pro-reforma-sobre.svg'
  },
  {
    iconName: "clip-path-group",
    iconPath: '../../../assets/img/menu/clip-path-group.svg'
  },
  {
    iconName: "bolt-menu",
    iconPath: '../../../assets/img/menu/bolt-menu.svg'
  },
  {
    iconName: "rating-star",
    iconPath: '../../../assets/img/avaliacao/rating-star.svg'
  }

];
