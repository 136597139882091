import { Component, OnInit, OnDestroy, DoCheck } from "@angular/core";
import { RevestimentoService } from "../revestimento/revestimento.service";
import { Subscription } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { IEnviroment } from "./../revestimento/models/i-enviroment";
import { EnvironmentsFilter } from "../revestimento/models/filters/environments-filter";
import { Router } from "@angular/router";
import { IStandard } from "../revestimento/models/i-standard";
import { IColor } from "../revestimento/models/i-color";
import { IStyle } from "../revestimento/models/i-style";
import { SharedService } from "../shared/shared.service";

@Component({
  selector: "pro-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.componente.css"],
})
export class HomeComponent implements OnInit, OnDestroy, DoCheck {
  constructor(
    private revestimentoService: RevestimentoService,
    private router: Router,
    private authService: SharedService
  ) {}

  subscritions = new Subscription();

  listEnviroment: IEnviroment[];
  listStandards: IStandard[];
  listColors: IColor[];
  listStandardsWall: IStandard[];
  listColorsWall: IColor[];
  listStile: IStyle[];

  listStandFilter: string[] = [];
  listStandWallFilter: string[] = [];
  listColorFilter: string[] = [];
  listColorWallFilter: string[] = [];
  listStileFilter: string[] = [];

  isShowModal: boolean = false;
  showFilter = false;
  showAplicar = false;

  clearFilters = false;
  countStandard = 0;
  countColor = 0;
  countColorWall = 0;
  countStandardWall = 0;
  countStyle = 0;

  chipsPiso: string[] = [];
  chipsPiso2: string[] = [];
  chipsWall: string[] = [];
  chipsWall2: string[] = [];
  chipsStile: string[] = [];

  indexRem: string;

  removerItem(item: string, type: string): void {

    const indfloor = this.listStandards.find(i => i.name === item);
    const indfloorcolor = this.listColors.find(i => i.name === item);
    const indwall = this.listStandardsWall.find(i => i.name === item);
    const indwallcolor = this.listColorsWall.find(i => i.name === item);
    const indstile = this.listStile.find(i => i.name === item);

    const indexfloor = this.chipsPiso.indexOf(item);
    const indexfloorcolor = this.chipsPiso2.indexOf(item);
    const indexwall = this.chipsWall.indexOf(item);
    const indexwallcolor = this.chipsWall2.indexOf(item);
    const indexStile = this.chipsStile.indexOf(item);

    if(type === "piso"){
      // Remove checkbox de PISO
      if(indfloor){
        this.indexRem = indfloor.id;
        console.log(this.indexRem);
        const indice = this.listStandFilter.indexOf(this.indexRem);
        this.listStandFilter.splice(indice,1);
        this.chipsPiso.splice(indexfloor, 1);
      }else if(indfloorcolor){
        this.indexRem = indfloorcolor.id;
        console.log(this.indexRem);
        const indice = this.listColorFilter.indexOf(this.indexRem);
        this.listColorFilter.splice(indice,1);
        this.chipsPiso2.splice(indexfloorcolor, 1);
      }

    } else if(type == "parede"){
      // Remove checkbox de PAREDE
      if(indwall){
        this.indexRem = indwall.id;
        console.log(this.indexRem);
        const indice = this.listStandWallFilter.indexOf(this.indexRem);
        this.listStandWallFilter.splice(indice,1);
        this.chipsWall.splice(indexwall, 1);
      }else if(indwallcolor){
        this.indexRem = indwallcolor.id;
        console.log(this.indexRem);
        const indice = this.listColorWallFilter.indexOf(this.indexRem);
        this.listColorWallFilter.splice(indice,1);
        this.chipsWall2.splice(indexwallcolor, 1);
      }
    } else{
      // Remove checkbox de ESTILO
      if(indstile){
        this.indexRem = indstile.id;
        console.log(this.indexRem);
        const indice = this.listStileFilter.indexOf(this.indexRem);
        this.listStileFilter.splice(indice, 1);
        this.chipsStile.splice(indexStile, 1);
      }
    }

  }

  async ngOnInit() {
    // localStorage.clear();
    this.authService.setPermission('');
    this.loadEnvironments();
    this.loadStandards();
    this.loadColors();
    this.loadStiles();
    this.countColor = 0;
    this.countStyle = 0;
    this.countStandard = 0;
    this.countColorWall = 0;
    this.countStandardWall = 0;
    this.revestimentoService.setShowFilter(true);
    this.revestimentoService.setShowFilterEnviroment(true);
  }

  ngDoCheck() {
    if (this.revestimentoService.hasFilterUpdate()) {
      const colors = this.revestimentoService.getColorFilter().toString();
      const stands = this.revestimentoService.getStandardFilter().toString();
      const stiles = this.revestimentoService.getStileFilter().toString();
      const colorsWall = this.revestimentoService
        .getColorWallFilter()
        .toString();
      const standsWall = this.revestimentoService
        .getStandardWallFilter()
        .toString();
      const environmentsFilter = new EnvironmentsFilter(
        colors,
        stands,
        stiles,
        colorsWall,
        standsWall
      );

      this.loadEnvironments(environmentsFilter);
    }
  }

  loadEnvironments(environmentsFilter?: EnvironmentsFilter) {
    this.subscritions.add(
      this.revestimentoService.getEnvironments(environmentsFilter).subscribe(
        (done) => {
          // console.log("done", done);

          this.listEnviroment = (done.result as IEnviroment[]).filter(
            (c) => c.imgUrl
          );

          this.revestimentoService.setFilterUpdate(false);
        },
        (err: HttpErrorResponse) => {
          console.error(err.message);
          this.revestimentoService.setFilterUpdate(false);
        }
      )
    );
  }

  loadStandards() {
    this.subscritions.add(
      this.revestimentoService.getStandards().subscribe(
        (done) => {
          this.listStandards = done.result as IStandard[];
          this.listStandardsWall = done.result as IStandard[];
        },
        (err: HttpErrorResponse) => {
          console.error(err.message);
        }
      )
    );
  }

  loadColors() {
    this.subscritions.add(
      this.revestimentoService.getColors().subscribe(
        (done) => {
          this.listColors = done.result as IColor[];
          this.listColorsWall = done.result as IColor[];
        },
        (err: HttpErrorResponse) => {
          console.error(err.message);
        }
      )
    );
  }

  loadStiles() {
    this.subscritions.add(
      this.revestimentoService.getStiles().subscribe(
        (done) => {
          this.listStile = done.result as IStyle[];
        },
        (err: HttpErrorResponse) => {
          console.error(err.message);
        }
      )
    );
  }

  addChips(chip: string [], list: any[], chipsFilter: string[] = []){
    if(chipsFilter == this.chipsPiso){
      this.chipsPiso = [];
      chip.forEach(id => {
        const itemEncontrado = list.find(item => item.id === id);
        if (itemEncontrado && !this.chipsPiso.includes(itemEncontrado.name)) {
          this.chipsPiso.push(itemEncontrado.name);
        }
  
      });
    }else if(chipsFilter == this.chipsPiso2){
      this.chipsPiso2 = [];
      chip.forEach(id => {
        const itemEncontrado = list.find(item => item.id === id);
        if (itemEncontrado && !this.chipsPiso2.includes(itemEncontrado.name)) {
          this.chipsPiso2.push(itemEncontrado.name);
        }
  
      });
    }else if(chipsFilter == this.chipsWall){
      this.chipsWall = [];
      chip.forEach(id => {
        const itemEncontrado = list.find(item => item.id === id);
        if (itemEncontrado && !this.chipsWall.includes(itemEncontrado.name)) {
          this.chipsWall.push(itemEncontrado.name);
        }
  
      });
    }else if(chipsFilter == this.chipsWall2){
      this.chipsWall2 = [];
      chip.forEach(id => {
        const itemEncontrado = list.find(item => item.id === id);
        if (itemEncontrado && !this.chipsWall2.includes(itemEncontrado.name)) {
          this.chipsWall2.push(itemEncontrado.name);
        }
  
      });
    }else if(chipsFilter == this.chipsStile){
      this.chipsStile = [];
      chip.forEach(id => {
        const itemEncontrado = list.find(item => item.id === id);
        if (itemEncontrado && !this.chipsStile.includes(itemEncontrado.name)) {
          this.chipsStile.push(itemEncontrado.name);
        }
  
      });
    }

  }

  setFilterStandards(event: string[]) {
    this.addChips(event, this.listStandards, this.chipsPiso);
    if (event && this.countStandard > 0) {
      this.listStandFilter = event;
      // if (!this.showAplicar) {
      //   this.sendFilter();
      // }
    }
    this.countStandard++;
  }

  setFilterColors(event: string[]) {
    this.addChips(event, this.listColors, this.chipsPiso2);
    if (event && this.countColor > 0) {
      this.listColorFilter = event;
      // if (!this.showAplicar) {
      //   this.sendFilter();
      // }
    }
    this.countColor++;
  }

  setFilterStandardsWall(event: string[]) {
    this.addChips(event, this.listStandardsWall, this.chipsWall);
    if (event && this.countStandardWall > 0) {
      this.listStandWallFilter = event;
      // if (!this.showAplicar) {
      //   this.sendFilter();
      // }
    }
    this.countStandardWall++;
  }

  setFilterColorsWall(event: string[]) {
    this.addChips(event, this.listColorsWall, this.chipsWall2);
    if (event && this.countColorWall > 0) {
      this.listColorWallFilter = event;
      // if (!this.showAplicar) {
      //   this.sendFilter();
      // }
    }
    this.countColorWall++;
  }

  setFilterStile(event: string[]) {
    this.addChips(event, this.listStile, this.chipsStile);
    // console.log("evsty1", event);
    if (event && this.countStyle > 0) {
      // console.log("evsty2", event);

      this.listStileFilter = event;
      // if (!this.showAplicar) {
      //   this.sendFilter();
      // }
    }
    this.countStyle++;
  }

  sendFilter() {
    // if (this.listColorFilter) {
    //   localStorage.setItem('colors', JSON.stringify(this.listColorFilter));
    //   this.revestimentoService.setColorFilter(this.listColorFilter);
    // }
    // if (this.listColorWallFilter) {
    //   localStorage.setItem('colorsWall', JSON.stringify(this.listColorWallFilter));
    //   this.revestimentoService.setColorWallFilter(this.listColorWallFilter);
    // }
    if (this.listStandFilter) {
      localStorage.setItem("standards", JSON.stringify(this.listStandFilter));
      this.revestimentoService.setStandardFilter(this.listStandFilter);
    }
    // if (this.listStandWallFilter) {
    //   localStorage.setItem('standardsWall', JSON.stringify(this.listStandWallFilter));
    //   this.revestimentoService.setStandardWallFilter(this.listStandWallFilter);
    // }
    // if (this.listStileFilter) {
    //   localStorage.setItem('styles', JSON.stringify(this.listStileFilter));
    //   this.revestimentoService.setStileFilter(this.listStileFilter);
    // }

    // if (this.listObrasFilter) {
    //   localStorage.setItem('obraPerformance', JSON.stringify(this.listObrasFilter));
    //   this.revestimentoService.setObrasFilter(this.listObrasFilter);
    // }

    // if (this.listDesempenhoFilter) {
    //   localStorage.setItem('materialPerformance', JSON.stringify(this.listDesempenhoFilter));
    //   this.revestimentoService.setDesempenhoFilter(this.listDesempenhoFilter);
    // }

    // this.menuRightClick();
  }

  ngOnDestroy(): void {
    this.subscritions.unsubscribe();
    this.revestimentoService.setShowFilter(false);
    this.revestimentoService.setShowFilterEnviroment(false);
  }

  goToPage(typeCoating: string) {
    this.revestimentoService.setParamnsSelected("typeCoating", typeCoating);
    this.router.navigate(["revestimento/piso-nao-selecionado"]);
  }

  showModalJumpAppearance(state: boolean) {
    this.isShowModal = state;
  }

  openFilter() {
    this.showFilter = true;
  }

  closeFilter() {
    if (this.showFilter) {
      this.showFilter = false;
    }
  }

  clearAllFilters() {
    this.clearFilters = !this.clearFilters;
  }

  saveAllFilters(){
    this.closeFilter();
    this.listEnviroment = undefined;
    this.sendFilter();
  }

  preventClickPropagation(event: Event) {
    event.stopPropagation();
  }
}
