import { Component, OnInit } from '@angular/core';
import { RevestimentoService } from "../revestimento/revestimento.service";
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { Location } from '@angular/common';

@Component({
  selector: 'pro-lead-resume',
  templateUrl: './lead-resume.component.html',
  styleUrls: ['./lead-resume.component.css']
})
export class LeadResumeComponent implements OnInit {

  constructor(
    private revestimentoService: RevestimentoService,
    private router: Router,
    private globals: Globals,
    private location: Location
  ) { }
  somaTotal: number = 0;
  leads: any[];
  public selected = [];
  url = this.router.routerState.snapshot.url;

  ngOnInit() {
    let email = localStorage.getItem('emailLead')
    this.revestimentoService.getLead(email).subscribe(res => {
      this.leads = res.result;
      // console.log(this.leads)
    }, er => {
      console.log("er", er);
      // alert('Usuario não encontrado')
      alert("Erro ao buscar reformas!")
    })
  }

  goBack() {
    if (this.url.includes('leadResume')) {
      this.router.navigate(["home"]);
    }
  }

  goToIntervention(environmentId, imgUrl, appearanceId, styleId, areaM2, nomeUser, emailUser, stateSelected, colorId, standardId, preexistenceId, materialId, actionId, idActionPossible) {

    window.localStorage.setItem("EnviromentId", environmentId);
    window.localStorage.setItem("imgurl", imgUrl);
    window.localStorage.setItem("AppearanceId", appearanceId);
    window.localStorage.setItem("StyleId", styleId);
    window.localStorage.setItem("AreaM2", areaM2);
    window.localStorage.setItem("nomeUser", nomeUser);
    window.localStorage.setItem("emailUser", emailUser);
    window.localStorage.setItem("stateSelected", stateSelected);
    window.localStorage.setItem("colorEnvId", colorId);
    window.localStorage.setItem("standardEnvId", standardId);

    window.localStorage.setItem("PreexistenceID", preexistenceId);
    window.localStorage.setItem("MaterialID", materialId);
    window.localStorage.setItem("PossibleActionID", actionId);
    window.localStorage.setItem("idActionPossible", idActionPossible);

    this.router.navigate(["/reforma/resumo-reforma/" + idActionPossible]);
  }

  goToMyList(environmentId, imgUrl, appearanceId, styleId, areaM2, nomeUser, emailUser, stateSelected, colorId, standardId, preexistenceId, materialId, actionId, idActionPossible, resumeProducts, interventionListIds) {

    window.localStorage.setItem("EnviromentId", environmentId);
    window.localStorage.setItem("imgurl", imgUrl);
    window.localStorage.setItem("AppearanceId", appearanceId);
    window.localStorage.setItem("StyleId", styleId);
    window.localStorage.setItem("AreaM2", areaM2);
    window.localStorage.setItem("nomeUser", nomeUser);
    window.localStorage.setItem("emailUser", emailUser);
    window.localStorage.setItem("stateSelected", stateSelected);
    window.localStorage.setItem("colorEnvId", colorId);
    window.localStorage.setItem("standardEnvId", standardId);

    window.localStorage.setItem("PreexistenceID", preexistenceId);
    window.localStorage.setItem("MaterialID", materialId);
    window.localStorage.setItem("PossibleActionID", actionId);
    window.localStorage.setItem("idActionPossible", idActionPossible);
    window.localStorage.setItem("resumeProducts", resumeProducts);
    window.localStorage.setItem("flagLeadReturn", "yes");




    try{
      if (resumeProducts.slice(-1)[0][0]){
        // console.log("resumeProducts.questionAnswer: ", resumeProducts.slice(-1)[0])
        let questionAnswer = JSON.stringify(resumeProducts.slice(-1)[0]);
        window.localStorage.setItem("questionAnswer", questionAnswer);
      }
    } catch(er){
      console.log("er", er);
    }

    window.localStorage.setItem("ids", interventionListIds);

    this.selected = resumeProducts;
    // console.log("selected: ", this.selected)

    this.globals.prodCat = [];;
    this.selected.forEach(element => {
      if(element && element.packagedProduct && Object.keys(element.packagedProduct).length > 0){
        this.globals.prodCat.push([element.packagedProduct.products.id, element.productCategoryId]);
      }

    });
    this.globals.sel = this.selected.filter(el => {
      return el != null;
    })
    // console.log("this.sel", this.globals.sel);

    // this.globals.sel = this.selected.sl
    this.globals.listProd = !this.globals.listProd;

    this.router.navigate(["/reforma/intervencao-one/"]);
  }

  getTotalAPartirDe(lead) {
    let totalAPartirDe = 0.0;
    if (lead.resumeProducts == null) return 0.0;
    for (let resumeProduct of lead.resumeProducts) {
      if (resumeProduct == null) continue;
      if (resumeProduct.packagedProduct == null) continue;
      if (resumeProduct.packagedProduct.minVlrConsumo == null) continue;
      totalAPartirDe += resumeProduct.packagedProduct.minVlrConsumo
    }

    return totalAPartirDe;
  }

}
