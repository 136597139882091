import { DetalhamentoComponent } from "./detalhamento/detalhamento.component";
import { HomeComponent } from "./home/home.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LeadResumeComponent } from './lead-resume/lead-resume.component';
import { SobreComponent } from "./sobre/sobre.component";
import { TermosComponent } from "./termos/termos.component";
import { CriteriosComponent } from "./criterios/criterios.component";
import { AvaliacaoComponent } from "./contato/avaliacao/avaliacao.component";
import { AuthGuard } from "./shared/auth-guard";

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "sobre", component: SobreComponent },
  { path: "termos", component: TermosComponent },
  { path: "criterios", component: CriteriosComponent },
  { path: "leadResume", component: LeadResumeComponent},
  { path: "avaliacao", component: AvaliacaoComponent, canActivate:[AuthGuard], data: {permission: 'evaluation'}},

  { path: "", pathMatch: "full", redirectTo: "home" },
  {
    path: "revestimento",
    loadChildren: () =>
      import("./revestimento/revestimento.module").then(
        m => m.RevestimentoModule
      )
  },
  {
    path: "reforma",
    loadChildren: () =>
      import("./reforma/reforma.module").then(m => m.ReformaModule)
  },
  {
    path: "contato",
    loadChildren: () =>
      import("./contato/contato.module").then(m => m.ContatoModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
