import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedRoutingModule } from "./shared-routing.module";
import {
  ProformaNavbarComponent,
} from "./proforma-navbar/proforma-navbar.component";
import { MasoryGridComponent } from "./masory-grid/masory-grid.component";
import { IsotopeModule } from "ngx-isotope";
import { FilterNavbarComponent } from "./filter-navbar/filter-navbar.component";
import { MatCheckboxModule } from '@angular/material/checkbox'

// material imports
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";
import { MatTooltipModule } from "@angular/material/tooltip";

import { MyListComponent } from "./my-list/my-list.component";
import { ProductScreenComponent } from "./product-screen/product-screen.component";
import { ProformaFooterComponent } from "./proforma-footer/proforma-footer.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { ExpansionPanelComponent } from "./expansion-panel/expansion-panel.component";
import { IframeDocComponent } from './iframe-doc/iframe-doc.component';
import { RatingStarsComponent } from './rating-stars/rating-stars.component';

@NgModule({
  providers: [],
  declarations: [
    ProformaNavbarComponent,
    MasoryGridComponent,
    FilterNavbarComponent,
    // ProductScreenComponent,
    MyListComponent,
    ProformaFooterComponent,
    ExpansionPanelComponent,
    IframeDocComponent,
    RatingStarsComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    IsotopeModule,
    FormsModule,
    MatIconModule,
    MatSidenavModule,
    MatRadioModule,
    MatTooltipModule,
    MatButtonModule,
    MatExpansionModule,
    MatCheckboxModule
  ],
  exports: [
    ProformaNavbarComponent,
    MasoryGridComponent,
    FilterNavbarComponent,
    MyListComponent,
    ProformaFooterComponent,
    ExpansionPanelComponent,
    IframeDocComponent,
    RatingStarsComponent
  ],
  entryComponents: [],
})
export class SharedModule {}
